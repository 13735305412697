import PropTypes from 'prop-types'
import React from 'react'
import { useDfpSlot } from 'SRC/modules/common/hooks/useDfpSlot'
import { Header, UserCard, SocServices, UserContacts } from '../../../shared/components'
import { getInfo } from 'SRC/modules/users/detail/selectors'
import { connect } from 'react-redux'
import { getIsMobileDevice } from 'SRC/modules/common/selectors'
import { userStatuses } from 'Core/constants'
import { getSelectableUserTypes } from 'SRC/modules/common/selectors'
import { isUserOnline } from 'SRC/utils'
import config from 'SRC/config/config.yaml'

const Banner = () => {
  useDfpSlot({
    id: config.banners.sidebar.userDetail.slotId || null,
    sizes: config.banners.sidebar.userDetail.sizes || [],
    path: config.banners.sidebar.userDetail ? `/${config.banners.dfpNetworkId}/${config.banners.sidebar.userDetail.adUnit}` : null
  })

  return <div id={config.banners.sidebar.userDetail.slotId} style={{ width: 300, height: 250 }} />
}

export class Container extends React.PureComponent {
  getUserTypeSeo = () => {
    const { info } = this.props
    return info && info.type ? info.type.seo : ''
  }

  getSidebarTitle = () => {
    const { info, userTypes } = this.props

    let title = ''

    if (info && info.type && info.type.seo && Array.isArray(userTypes)) {
      const findUserType = userTypes.find(item => item.seo === info.type.seo)

      if (findUserType) {
        title = findUserType.name
      }
    }

    return title
  }

  getUserCardInfo = () => {
    const isBusinessUser = this.isBusinessUser()
    const { info } = this.props

    const mainAddress = info && info.userAddresses && this.getMainAddress(info.userAddresses)

    const arLogo = info && Array.isArray(info.media) && info.media.filter(item => item.title === 'profile-picture')

    return info
      ? {
        name: info.name,
        login: info.username,
        status: isUserOnline(info.active) ? userStatuses.online : userStatuses.offline,
        isEmailVerified: Boolean(info.email && info.email.verified),
        isPhoneVerified: true,
        isAddressVerified: false,
        gender: info.gender,
        type: isBusinessUser ? 'business' : 'free',
        image: isBusinessUser && arLogo.length ? arLogo[0] : null,
        addressCount: isBusinessUser ? info.userAddresses.length : 0,
        activeAddons: info.activeAddons && info.activeAddons.length ? info.activeAddons : null,
        city: isBusinessUser ? mainAddress && mainAddress.city ? mainAddress.city.name : null : null,
        isPro: false
      }
      : null
  }

  getUserContactsInfo = () => {
    return this.props.info
      ? {
        type: this.isBusinessUser() ? 'business' : 'free',
        addresses: this.props.info.userAddresses,
        phones: this.props.info.phones,
        login: this.props.info.username,
        seo: this.props.info.type && this.props.info.type.seo
      }
      : null
  }

  isBusinessUser = () => {
    const seo = this.getUserTypeSeo()

    return seo !== config.userTypes.freeUserTypeSeo
  }

  getMainAddress = addresses => {
    if (Array.isArray(addresses)) {
      const searchedAddress = addresses.filter(address => address.main)
      if (searchedAddress.length) {
        return searchedAddress[0]
      } else {
        return null
      }
    }
  }

  render () {
    const { isMobileDevice } = this.props

    const userCardInfo = this.getUserCardInfo()
    const userContactsInfo = this.getUserContactsInfo()
    const title = this.getSidebarTitle()

    return (
      <aside className='sidebar'>
        <div className='sidebar-prodavac'>
          {title ? <Header title={title} /> : null}
          <div className={`sidebar-content ${this.isBusinessUser() ? `sidebar-user-prodavnica` : `sidebar-user-free`}`}>
            <div className='sidebar-user'>
              {userCardInfo && <UserCard user={userCardInfo} />}
              {userContactsInfo && <UserContacts user={userContactsInfo} />}
            </div>
          </div>
        </div>

        {!isMobileDevice ? <Banner /> : null}

        <SocServices />
      </aside>
    )
  }
}

const mapStateToProps = state => ({
  info: getInfo(state),
  userTypes: getSelectableUserTypes(state),
  isMobileDevice: getIsMobileDevice(state)
})

Container.displayName = 'UserDetailSidebar'

Container.propTypes = {
  info: PropTypes.any,
  userTypes: PropTypes.arrayOf(PropTypes.shape({
    seo: PropTypes.string
  })).isRequired,
  isMobileDevice: PropTypes.bool.isRequired
}

export default connect(mapStateToProps)(Container)
