import { useEffect } from 'react'

export const useDfpSlot = ({ path, sizes, id }) => {
  useEffect(() => {
    if (!path || !id) return

    const googletag = window.googletag || {}

    googletag.cmd = googletag.cmd || []

    googletag.cmd.push(() => {
      console.log('gtag', googletag)
      // googletag.defineSlot(path, sizes, id).addService(googletag.pubads())

      // googletag.pubads().disableInitialLoad()
      // googletag.pubads().enableSingleRequest()
      // googletag.pubads().collapseEmptyDivs()

      // googletag.enableServices()


      googletag.display(id)
    });
  }, [path, id, sizes])
}
