import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Header } from '../Header'
import { withRouter } from 'next/router'
import { getCurrentAd } from 'src/modules/ads/detail/selectors'
import { useDfpSlot } from 'SRC/modules/common/hooks/useDfpSlot'
import config from 'SRC/config/config.yaml'

import { Facebook, Viber, WhatsApp, Twitter, Email, Telegram } from './../index'

const Container = ({ displayBanner, router, ad }) => {
  useDfpSlot({
    id: config.banners.sidebar.social?.slotId || null,
    sizes: config.banners.sidebar.social?.sizes || [],
    path: config.banners.sidebar.social
      ? `/${config.banners.dfpNetworkId}/${config.banners.sidebar.social.adUnit}`
      : null
  })

  const asPath = router.asPath
  let baseUrl = config.siteUrl

  const adLink = ad && ad.link ? ad.link : null

  if (adLink) baseUrl = adLink.startsWith('http') ? '' : 'https://oglasi.me'

  return (
    <div className='kontakt'>
      <Header />
      <div className='sidebar-content clearfix'>
        <Facebook asPath={asPath} adLink={adLink} baseUrl={baseUrl}  />
        <Twitter asPath={asPath} adLink={adLink} baseUrl={baseUrl}  />
        <WhatsApp asPath={asPath} adLink={adLink} baseUrl={baseUrl}  />
        <Viber asPath={asPath} adLink={adLink} baseUrl={baseUrl}  />
        <Email asPath={asPath} adLink={adLink} baseUrl={baseUrl}  />
        <Telegram asPath={asPath} adLink={adLink} baseUrl={baseUrl}  />
      </div>

      {displayBanner && config.banners.sidebar && config.banners.sidebar.social ? (
        <div id={config.banners.sidebar.social.slotId} style={{ width: 300, height: 'auto' }} />
      ) : null}
    </div>
  )
}

Container.propTypes = {
  displayBanner: PropTypes.bool,
  router: PropTypes.shape({
    asPath: PropTypes.string.isRequired
  }),
  ad: PropTypes.shape({
    link: PropTypes.string.isRequired
  })
}

Container.defaultProps = {
  displayBanner: true
}

Container.displayName = 'SidebarSocServices'
export default compose(connect(state => ({ ad: getCurrentAd(state) })), withRouter)(Container)
