import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { SocServices, Header, UserCard, UserContacts } from '../../../shared/components'
import { connect } from 'react-redux'
import { useDfpSlot } from 'SRC/modules/common/hooks/useDfpSlot'
import { getIsMobileDevice } from 'SRC/modules/common/selectors'
import { getCurrentAd } from 'SRC/modules/ads/detail/selectors'
import { getSelectableUserTypes } from 'SRC/modules/common/selectors'
import { SimilarAds } from 'SRC/modules/ads/detail/components/Tab/SimilarAds'
import { QuickSearch } from 'SRC/modules/categories/components'
import { userStatuses } from 'Core/constants'
import { isUserOnline } from 'SRC/utils'
import config from 'SRC/config/config.yaml'

const renderSimilarAds = () => {
  return (
    <React.Fragment>
      <div className='oglasi-tab-header' style={{ width: '100%', marginTop: '.5rem' }}>
        <ul>
          <li className='active'>
            <a href='javascript:void(0)'><i className='ico-tab-slicni-oglasi ico' />Slični oglasi</a>
          </li>
        </ul>
      </div>
      <SimilarAds />
    </React.Fragment>
  )
}

const Banner = () => {
  useDfpSlot({
    id: config.banners.sidebar.adDetail.slotId || null,
    sizes: config.banners.sidebar.adDetail.sizes || [],
    path: config.banners.sidebar.adDetail ? `/${config.banners.dfpNetworkId}/${config.banners.sidebar.adDetail.adUnit}` : null
  })

  return <div id={config.banners.sidebar.adDetail.slotId} style={{ width: 300, height: 250 }} />
}

const MobileBottomBanner = () => {
  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    const handleWindowResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  const slot = config.banners.sidebar.adDetailMobileBottom

  useDfpSlot({
    id: slot.slotId || null,
    sizes: slot.sizes || [],
    path: slot ? `/${config.banners.dfpNetworkId}/${slot.adUnit}` : null
  })

  return <div id={slot.slotId} style={{ width: windowWidth <= 727 ? 320 : 728, margin: 'auto' }} />
}

export class Container extends React.Component {
  getUserTypeSeo = () => {
    const { ad } = this.props
    return ad && ad.user && ad.user.type ? ad.user.type.seo : ''
  }

  getSidebarTitle = () => {
    const { userTypes, ad } = this.props

    let title = ''

    if (ad && ad.user && ad.user.type) {
      title = ad.user.type.name
    } else {
      const userTypeSeo = this.getUserTypeSeo()

      if (userTypeSeo && Array.isArray(userTypes)) {
        const findUserType = userTypes.find(item => item.seo === userTypeSeo)

        if (findUserType) {
          title = findUserType.name
        }
      }
    }

    return title
  }

  isBusinessUser = () => {
    const seo = this.getUserTypeSeo()

    return seo !== config.userTypes.freeUserTypeSeo
  }

  getUserCardInfo = () => {
    const isBusinessUser = this.isBusinessUser()
    const { ad } = this.props
    const mainAddress = ad && ad.user && this.getMainAddress(ad.user.userAddresses)
    return ad && ad.user
      ? {
        name: ad.user.name,
        login: ad.user.username,
        status: isUserOnline(ad.user.active) ? userStatuses.online : userStatuses.offline,
        isEmailVerified: Boolean(ad.user.emailVerified),
        isPhoneVerified: true,
        isAddressVerified: false,
        gender: ad.user.gender,
        type: isBusinessUser ? 'business' : 'free',
        image: isBusinessUser ? ad.user.logo : null,
        addressCount: isBusinessUser ? ad.user.userAddresses.length : 0,
        city: isBusinessUser ? mainAddress && mainAddress.city ? mainAddress.city.name : null : null,
        isPro: false
      }
      : null
  }

  getMainAddress = addresses => {
    if (Array.isArray(addresses)) {
      const searchedAddress = addresses.filter(address => address.main)
      if (searchedAddress.length) {
        return searchedAddress[0]
      } else {
        return null
      }
    }
  }

  getUserContactsInfo = () => {
    return this.props.ad && this.props.ad.user
      ? {
        type: this.isBusinessUser() ? 'business' : 'free',
        addresses: this.props.ad.user.userAddresses,
        phones: this.props.ad.user.phones,
        login: this.props.ad.user.username,
        seo: this.getUserTypeSeo()
      }
      : null
  }

  render() {
    const { ad, isMobileDevice } = this.props

    const userCardInfo = this.getUserCardInfo()
    const userContactsInfo = this.getUserContactsInfo()
    const title = this.getSidebarTitle()

    return (
      <React.Fragment>
        <aside className='sidebar sidebar__ad-details'>
          <div className='sidebar-prodavac'>
            {title && <Header title={title} />}
            <div className={`sidebar-content ${this.isBusinessUser() ? `sidebar-user-prodavnica` : `sidebar-user-free`}`}>
              <div className='sidebar-user'>
                {userCardInfo && <UserCard user={userCardInfo} />}
                {userContactsInfo && <UserContacts user={userContactsInfo} adStatus={ad ? ad.status : null} />}
              </div>
            </div>
          </div>

          {!isMobileDevice ? <Banner /> : <MobileBottomBanner />}

          <SocServices />
        </aside>

        {isMobileDevice ? (
          <>
            {renderSimilarAds()}

            <div style={{ marginTop: 3, width: '100%' }}>
              <QuickSearch page='ad' />
            </div>
          </>
        ) : null}
      </React.Fragment>
    )
  }
}

Container.displayName = 'AdDetailSidebar'

Container.propTypes = {
  ad: PropTypes.shape({
    status: PropTypes.string.isRequired,
    user: PropTypes.shape({
      type: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      gender: PropTypes.string,
      phones: PropTypes.array,
      userAddresses: PropTypes.array,
      logo: PropTypes.object,
      emailVerified: PropTypes.bool,
      active: PropTypes.string
    })
  }),
  userTypes: PropTypes.arrayOf(PropTypes.shape({
    seo: PropTypes.string
  })).isRequired,
  isMobileDevice: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  userTypes: getSelectableUserTypes(state),
  ad: getCurrentAd(state),
  isMobileDevice: getIsMobileDevice(state)
})

export default connect(mapStateToProps)(Container)
