import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useDfpSlot } from 'SRC/modules/common/hooks/useDfpSlot'
import { CookiesHelper } from 'SRC/core/cookiesHelper/CookiesHelper'
import { getUsersWithRandomOrder, getUserProAddon } from '../../functions/functions'
import { SocServices } from '../../../shared/components'
import { Sellers } from '../BusinessUsers'
import { getSelectableUserTypes } from 'SRC/modules/common/selectors'
import { fetchUsers } from 'SRC/modules/users/listSidebar/actions'
import { getSidebarUsersByType } from 'SRC/modules/users/listSidebar/selectors'
import config from 'SRC/config/config.yaml'

const Banner = ({ index }) => {
  useDfpSlot({
    id: config.banners.sidebar.adsList[index]?.slotId || null,
    sizes: config.banners.sidebar.adsList[index]?.sizes || [],
    path: config.banners.sidebar.adsList[index]
      ? `/${config.banners.dfpNetworkId}/${config.banners.sidebar.adsList[index].adUnit}`
      : null
  })

  return <div id={config.banners.sidebar.adsList[index].slotId} style={{ width: 300, height: 250 }} />
}

class Container extends React.Component {
  constructor (props) {
    super(props)

    this.config = config.sidebar.userTypesSmsAds

    this.state = { users: [] }

    this.cookies = new CookiesHelper()
  }

  componentDidMount () {
    if (this.props.userTypes.length) this.fetchUsers()
  }

  async fetchUsers () {
    const userTypesIds = this.props.userTypes.map(type => type.id)

    const fields = {
      page: 1,
      limit: 50,
      types: userTypesIds
    }

    await this.props.fetchUsers(fields)

    this.loadUsersIntoState()
    this.usersRenewalTimer = setInterval(() => {
      this.loadUsersIntoState()
    }, this.config.usersListRenewalInterval)
  }

  componentWillUnmount () {
    clearInterval(this.usersRenewalTimer)
    window.clearInterval(this.interval)
  }

  loadUsersIntoState = () => {
    const { usersByType } = this.props

    let newUsers = []
    let usersWithUrl = []

    if (usersByType && usersByType.length) {
      usersByType.forEach(type => {
        const users = type.users
        if (users && users.length) {
          usersWithUrl = usersWithUrl.concat(users.map(user => ({
            ...user,
            url: {
              href: `/user?userType=${user.type.seo}&username=${user.username}`,
              as: `/${user.type.seo}/${user.username}`
            }
          })))
        }
      })
    }

    if (usersWithUrl.length) {
      const premiumUsers = this.filterPremiumUsers(usersWithUrl)
      newUsers = premiumUsers
    }

    newUsers = getUsersWithRandomOrder(newUsers, this.config.usersCountPerPair)

    this.setState({ users: newUsers })
  }

  filterPremiumUsers = users => {
    return users.filter(user => {
      const proAddon = getUserProAddon(user)
      return proAddon && proAddon.name !== this.config.proAddonName
    })
  }

  renderUsers = (title, info) => {
    const { users } = this.state

    return <div className='izdvojeno-iz-ponude'>
      <Sellers users={users} title={title} info={info} /></div>
  }

  renderItems = () => {
    const items = this.config.display.default

    return items.map(item => {
      if (item.hasOwnProperty('banner')) return <Banner index={item.banner} />
      if (item.users) return this.renderUsers(item.users.title, item.users.info)
      return null
    })
  }

  render () {
    return <aside className='sidebar'>
      {this.renderItems()}
      <SocServices displayBanner={false} />
    </aside>
  }
}

const mapStateToProps = state => {
  const selectableUserTypes = getSelectableUserTypes(state)
  return {
    userTypes: selectableUserTypes.filter(type => type.seo !== 'korisnik'),
    usersByType: getSidebarUsersByType(state)
  }
}

Container.propTypes = {
  userTypes: PropTypes.array.isRequired,
  usersByType: PropTypes.array.isRequired,
  fetchUsers: PropTypes.func.isRequired
}

export default connect(mapStateToProps, { fetchUsers })(Container)
