import PropTypes from 'prop-types'
import React from 'react'
import { Head, Footer, Sidebar, Header } from '../../layout'
import { ErrorNotification } from '../../../ui/ErrorNotification'
import { connect } from 'react-redux'
import { useDfpSlot } from 'SRC/modules/common/hooks/useDfpSlot'
import { getAuthError } from '../../users/Auth/selectors'
import { setAuthError } from '../../users/Auth/actions'
import { getAppLoading, getIsMobileDevice } from '../../common/selectors'
import { setIsMobileDevice } from '../../common/actions'
import { getCurrentAd } from 'SRC/modules/ads/detail/selectors'
import { CookieConsent } from 'SRC/modules/common/components/CookieConsent'
import { AppLink } from 'SRC/modules/common/components/AppLink'
import { ScrollToTopButton } from 'SRC/modules/common/components/ScrollToTopButton'
import TopLoader from 'react-top-loader'
import config from 'SRC/config/config.yaml'

import { SideBanners } from '../SideBanners'

const Layout = ({
  setAuthError,
  children,
  error,
  ad,
  title,
  isLoading,
  layoutStyle,
  isMobileDevice,
  isUserSearch,
  pageUrlItems,
  setIsMobileDevice
}) => {
  React.useEffect(() => { window.addEventListener('resize', windowSizeChanged) }, [])

  const windowSizeChanged = () => {
    const width = window.innerWidth

    console.log('windowSizeChanged', width)

    if (isMobileDevice && width > 1000) setIsMobileDevice(false)
    if (!isMobileDevice && width <= 1000) setIsMobileDevice(true)
  }

  const hideError = () => setAuthError('')

  return (
    <div className='container'>
      <Head
        ad={ad}
        title={title}
        pageUrlItems={pageUrlItems} 
        isUserSearch={isUserSearch}
      />

      <Header />
      <main>
        {children}
        {layoutStyle === 'default' ? <Sidebar /> : null}
      </main>
      <Footer />
      <CookieConsent />
      <TopLoader color='#a57bdb' show={isLoading} className='top-loader' />
      <ErrorNotification hideNotification={hideError} error={error} />

      {!isMobileDevice && config.banners.background ? <SideBanners /> : null}

      <ScrollToTopButton />
    </div>
  )
}

const mapStateToProps = state => ({
  error: getAuthError(state),
  isLoading: getAppLoading(state),
  ad: getCurrentAd(state),
  isMobileDevice: getIsMobileDevice(state)
})

Layout.propTypes = {
  ad: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
  error: PropTypes.string,
  pageUrlItems: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  isMobileDevice: PropTypes.string.isRequired,
  isUserSearch: PropTypes.bool,
  layoutStyle: PropTypes.string.isRequired,
  setAuthError: PropTypes.func.isRequired,
  setIsMobileDevice: PropTypes.func.isRequired
}

Layout.defaultProps = {
  error: '',
  isLoading: false
}

export default connect(mapStateToProps, { setAuthError, setIsMobileDevice })(Layout)
