import React from 'react'
import { useDfpSlot } from 'SRC/modules/common/hooks/useDfpSlot'
import config from 'SRC/config/config.yaml'

import { SocServices } from '../../../shared/components'

export const Default = () => {
  useDfpSlot({
    id: config.banners.sidebar.default?.slotId || null,
    sizes: config.banners.sidebar.default?.sizes || [],
    path: config.banners.sidebar.default ? `/${config.banners.dfpNetworkId}/${config.banners.sidebar.default.adUnit}` : null
  })

  return (
    <aside className='sidebar'>
      {config.banners.sidebar.default ? (
        <div
          id={config.banners.sidebar.default.slotId}
          style={{ width: 300, height: 250 }}
        />
      ) : null}
      <SocServices />
    </aside>
  )
}

Default.displayName = 'DefaultSidebar'
