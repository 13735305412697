import '../public/scss/app.scss'
import { registerServiceWorker } from '../sw'

import React from 'react'
import { Provider } from 'react-redux'
import App from 'next/app'

import withRedux from 'next-redux-wrapper'
import { makeStore } from '../src/modules/redux/store'
import { globalOptions } from '../src/core/constants'
import { Layout, Head } from '../src/modules/layout'
import { BrowserNotSupported } from '../src/modules/staticInfo'
import { fetchCategories, fetchCategoriesWithGroups, setSimilarWords } from '../src/modules/categories/actions'
import { fetchBrands } from 'SRC/modules/ads/brands/actions'
import { extractSimilarWords } from '../src/modules/categories/functions'
import { fetchUserInfo } from '../src/modules/users/Profile/actions'
import { CookiesHelper } from '../src/core/cookiesHelper/CookiesHelper'
import Router from 'next/router'
import {
  setRouter,
  setAppLoading,
  setIsTransitioning,
  setAppState,
  fetchPageMetaTags,
  setUserTypes,
  setSelectableUserTypes,
  setIsMobileDevice
} from '../src/modules/common/actions'
import { userTypes as userTypesData } from '../src/modules/common/data/userTypes'
import { selectableUserTypes, selectableUserTypes as selectableUserTypesData } from '../src/modules/common/data/selectableUserTypes'
import { isBrowserSupported, flattenWithChildren } from '../src/utils'
import { getAppInternalTransitionFunc } from '../src/modules/common/selectors'
import config from 'SRC/config/config.yaml'


class OglasiApp extends App {
  constructor(props) {
    super(props)

    this.routes = []

    if (Router.router) {
      Router.router.events.on('routeChangeStart', this.onRouteChangeStartHandler)
      Router.router.events.on('routeChangeComplete', this.onRouteChangeCompleteHandler)
      Router.router.events.on('routeChangeError', this.onRouteChangeErrorHandler)
    }
  }

  componentDidMount() {
    process.env.NEXT_PUBLIC_NAME !== 'development' && registerServiceWorker()

    setTimeout(() => {
      window.googletag = window.googletag || { cmd: [] };
      googletag.cmd.push(function () {
        googletag.pubads().refresh()
      })
    }, 1000)
  }

  onRouteChangeStartHandler = async url => {
    if (this.props) {
      const { store } = this.props
      if (store) {
        const appState = store.getState()
        const func = getAppInternalTransitionFunc(appState)
        const isInternalTransition = func && func(url)
        if (!isInternalTransition) {
          store.dispatch(setAppLoading(true))
        }

        store.dispatch(setIsTransitioning(true))

        window.googletag = window.googletag || { cmd: [] };
        googletag.cmd.push(function () {
          googletag.pubads().refresh()
        })
      }
    }

    this.pushCurrentRouteInfo()
  }

  onRouteChangeCompleteHandler = async url => {
    if (this.props) {
      const { store } = this.props
      if (store) {
        store.dispatch(setAppLoading(false))
        const appState = store.getState()
        const func = getAppInternalTransitionFunc(appState)
        const isInternalTransition = func && func(url)
        if (!isInternalTransition) {
          // window.scrollTo(0, 0)
        }

        store.dispatch(setIsTransitioning(false))
      }
    }

    this.fixScrollPosition()
  }

  onRouteChangeErrorHandler = async () => {
    if (this.props) {
      const { store } = this.props
      if (store) {
        store.dispatch(setAppLoading(false))
      }
    }
  }

  isBack = () => {
    return this.routes.length >= 2 && Router.pathname === this.routes[this.routes.length - 2].pathname
  }

  fixScrollPosition = () => {
    let scrollY = 0

    if (this.routes.length >= 2) {
      const lastRoute = this.routes[this.routes.length - 1]
      const beforeLastRoute = this.routes[this.routes.length - 2]

      const adListRoutes = ['/', '/category', '/search', '/user']

      if (lastRoute && beforeLastRoute) {
        const lastRouteContainsAds = adListRoutes.includes(lastRoute.pathname)
        const beforeLastRouteContainsAds = adListRoutes.includes(beforeLastRoute.pathname)
        if (lastRouteContainsAds && beforeLastRouteContainsAds) {
          this.routes = []
        }
      }
    }

    if (this.isBack()) {
      this.routes.pop() // route where we come from
      const targetRoute = this.routes.pop() // route where we return
      scrollY = targetRoute.scrollY // scrollY we had before
    }

    window.requestAnimationFrame(() => window.scrollTo(0, scrollY))
  }

  pushCurrentRouteInfo = () => {
    this.routes.push({ pathname: Router.pathname, scrollY: window.scrollY })
  }

  static async getInitialProps({ Component, router, ctx }) {
    const state = ctx.store.getState()
    const { route, asPath, pathname, query } = router
    const cookies = new CookiesHelper()
    ctx.store.dispatch(setRouter({ route, asPath, pathname, query }))
    let isTokenExisted, id, token, renderBrowserNotSupported

    if (ctx.req) {
      ctx.store.dispatch(setUserTypes(userTypesData))
      ctx.store.dispatch(setSelectableUserTypes(selectableUserTypesData))

      // await Promise.all([
      //   ctx.store.dispatch(fetchCategories()),
      //   ctx.store.dispatch(fetchCategoriesWithGroups())
      // ])

      if (['/addAd', '/categories', '/profile', '/registration'].includes(ctx.pathname)) {
        const categories = ctx.store.getState().categories.elements
        const flatCategories = flattenWithChildren(categories)
        const similarWords = extractSimilarWords(flatCategories)
        ctx.store.dispatch(setSimilarWords(similarWords))
      }

      isTokenExisted = Boolean(ctx.req.cookies && ctx.req.cookies.token && ctx.req.cookies.userId)
      id = ctx.req.cookies.userId
      token = ctx.req.cookies.token
      if (ctx.req.params && ctx.req.params.userId && ctx.req.params.token) {
        isTokenExisted = true
        id = ctx.req.params.userId
        token = ctx.req.params.token
      }

      if (ctx.req.browser) {
        renderBrowserNotSupported = !isBrowserSupported(ctx.req.browser)
      }

      console.log('ctx.req.mobileOS', ctx.req.mobileOS)

      if (ctx.req.isMobile) {
        const mobileOS = ctx.req.mobileOS && (ctx.req.mobileOS.startsWith('iOS') || ctx.req.mobileOS.startsWith('OS X'))
          ? 'iOS'
          : 'Android'
        ctx.store.dispatch(setIsMobileDevice(mobileOS))
      } else {
        ctx.store.dispatch(setIsMobileDevice(false))
      }
    } else {
      // await ctx.store.dispatch(fetchCategories())
      // await ctx.store.dispatch(fetchCategoriesWithGroups())

      if (['/addAd', '/categories', '/profile', '/registration'].includes(ctx.pathname)) {
        const categories = ctx.store.getState().categories.elements
        const flatCategories = flattenWithChildren(categories)
        const similarWords = extractSimilarWords(flatCategories)
        ctx.store.dispatch(setSimilarWords(similarWords))
      }

      isTokenExisted = Boolean(cookies.get('token') && cookies.get('userId'))
      id = cookies.get('userId')
      token = cookies.get('token')
    }

    await ctx.store.dispatch(fetchPageMetaTags())

    if (isTokenExisted) {
      const needCountActiveAds = ctx.pathname === '/profile' || ctx.pathname === '/editAd'
      await ctx.store.dispatch(fetchUserInfo(id, token, needCountActiveAds))
    }

    const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {}
    pageProps.renderBrowserNotSupported = renderBrowserNotSupported
    if (renderBrowserNotSupported) pageProps.title = `Browser is not supported | ${config.siteName}`

    const isTransitioning = ctx.store.getState().common.transitions.isTransitioning

    if (isTransitioning) ctx.store.dispatch(setAppState(pageProps.state))

    // ctx.store.dispatch(setAppState(pageProps.state))
    return { pageProps }
  }

  App = pageProps => (
    <div className='container__app'>
      <Head title={pageProps.title} />
      <main className='forApp'>
        {pageProps.children}
      </main>
    </div>
  )

  renderLayout = (pageProps, Component) => {
    const App = this.App

    const appComponents = ['termsofserviceapp', 'vehicleregistrationoldapp', 'privacypolicyapp',
      'registrationcalculatorapp', 'partsrequestapp', 'activatepromockbapp', 'consumerrightsapp',
      'secureshoppingapp', 'helpapp'
    ]

    const isForApp = appComponents.includes(pageProps.component)

    if (pageProps.renderBrowserNotSupported) {
      return (
        <React.Fragment>
          <Head title={pageProps.title} />
          <BrowserNotSupported />
        </React.Fragment>
      )
    }

    if (pageProps.underConstruction) {
      return <Component />
    }

    if (pageProps.component && isForApp) {
      return <App {...pageProps}><Component {...pageProps} isForApp={isForApp} /></App>
    }

    return (
      <Layout
        isForApp={isForApp}
        title={pageProps.title}
        layoutStyle={pageProps.layoutStyle || 'default'}
        pageUrlItems={pageProps.pageUrlItems || null}
        isUserSearch={pageProps.isUserSearch || false}
      >
        <Component {...pageProps} />
      </Layout>
    )
  }

  render() {
    const { Component, pageProps, store } = this.props
    return (
      <React.Fragment>
        <noscript><iframe src={`https://www.googletagmanager.com/ns.html?id=${config.head.gaID}`} height='0' width='0'
          style={{ display: 'none', visibility: 'hidden' }} /></noscript>
        <Provider store={store}>
          {this.renderLayout(pageProps, Component)}
        </Provider>
      </React.Fragment>
    )
  }
}

export default withRedux(makeStore)(OglasiApp)
