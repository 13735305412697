import React from 'react'
import { Header, SocServices } from '../../../shared/components'
import { UserfulInfoItem } from '../UsefulInfo'
import { useDfpSlot } from 'SRC/modules/common/hooks/useDfpSlot'
import config from 'SRC/config/config.yaml'

const usefulInfoItems = config.sidebar.addAd.usefulInfoItems.map((item, index) => ({
  id: index + 1,
  title: item.title,
  content: item.content,
  contentBold: item.contentBold,
  icon: item.icon,
  url: item.url
}))

export const Container = () => {
  useDfpSlot({
    id: config.banners.sidebar.addAd.slotId || null,
    sizes: config.banners.sidebar.addAd.sizes || [],
    path: config.banners.sidebar.addAd ? `/${config.banners.dfpNetworkId}/${config.banners.sidebar.addAd.adUnit}` : null
  })

  return (
    <aside className='sidebar'>
      <div className='sidebar-korisne-informacije'>
        <Header title='Korisne informacije' />
        <div className='sidebar-content'>
          {usefulInfoItems.map(item => (<UserfulInfoItem key={item.id} item={item} />))}
        </div>
      </div>

      <div id={config.banners.sidebar.addAd.slotId} style={{ width: 300, height: 250 }} />

      <SocServices />
    </aside>
  )
}
